<template>
  <footer class="footer">
    <div class="footer_wrap">
      <nav class="footer__sns">
        <ul class="footer__sns-items">
          <li class="footer__sns-item"><a
            class="footer__sns-link facebook"
            href="https://www.facebook.com/Hyundaiworldwide"
          >Facebook</a></li>
          <li class="footer__sns-item"><a
            class="footer__sns-link twitter"
            href="https://twitter.com/hyundai_global"
          >Twitter</a></li>
          <li class="footer__sns-item"><a
            class="footer__sns-link instagram"
            href="https://www.instagram.com/hyundai/"
          >Instagram</a></li>
          <li class="footer__sns-item"><a
            class="footer__sns-link youtube"
            href="https://www.youtube.com/channel/UC5f97D60yHa7UE9rFfbej8g"
          >Youtube</a></li>
          <li class="footer__sns-item"><button
            type="button"
            class="footer__sns-link share"
            @click="openSharePopup"
          >Share</button></li>
        </ul>
      </nav>
      <h2 class="footer__title"><a
        href="https://www.hyundai.com/worldwide/en/"
        target="_blank"
      >Worldwide</a></h2>
      <ul class="footer__escape-clauses">
        <li class="footer__escape-clauses-item fs_sans_text fs_regular">{{ $t('component.Footer.001') }}</li>
        <li class="footer__escape-clauses-item fs_sans_text fs_regular">{{ $t('component.Footer.002') }}</li>
      </ul>
      <hr class="line">
      <div class="footer__logo-wrap">
        <ul class="footer__logo">
          <li class="footer__logo-item hyundai">Hyundai</li>
          <li class="footer__logo-item shell">Shell</li>
        </ul>
        <p class="footer__together">Hyundai and Shell working together</p>
      </div>
      <div class="footer__awards-wrap">
        <ul class="footer__awards">
          <li class="footer__awards-item if">iF Design Award 2024</li>
        </ul>
      </div>
      <div class="footer__copyright fs_sans_text">
        <span>ⓒ 2023 Hyundai Motor Company</span>
      </div>
    </div>
  </footer>
</template>

<script>
import { useSettingsStore } from '@/stores/settings'
export default {
  name: "FooterComponent",
  data() {
    return {
      store: useSettingsStore()
    }
  },
  methods: {
    openSharePopup() {
      this.store.toggleSharePopup()
    }
  }
}
</script>
